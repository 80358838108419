import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import RiskFilterContext, { defaultRiskFilters } from 'context/RiskFilterContext';
import MapBoxMap from 'components/MapBoxMap';
import MapWrapper from 'components/LocationMap';
import HazardImpactViewer from 'components/HazardImpactViewer';
import RiskGraph from 'components/RiskGraph';
import HazardVulnerabilityDrawer from 'components/HazardVulnerabilityDrawer';
import DashboardHeader from 'components/DashboardHeader';
import Disclaimer from 'components/Disclaimer';
import riskService from 'services/riskService';
import { getAsset, getAllAssetsForNavigation } from 'services/assetService';
import impactFunctionService from 'services/impactFunctionService';
import pathParser from 'utils/pathParser';
import googleAnalyticsEvent from 'utils/googleAnalyticsEvent';
import { Card, H5 } from '@spglobal/react-components';
import { HAZARD_ONLY } from 'utils/constants';
import LocationInfoTable from 'components/LocationInfoTable';
import { useApiGet } from 'hooks/useApiGet';
import { DEFAULT_PAGE_SIZE } from '../components.constants';

export default function AssetDetails({ entityType, match }) {
    const history = useHistory();
    const { pathname } = useLocation();
    const { riskDataFilters, handleFilterChange } = useContext(RiskFilterContext);
    const {
        'real-assets': customerId,
        folders: folderId,
        assets: assetId,
        views: viewId,
    } = pathParser.getPathComponents(pathname);
    const [isMounted, setIsMounted] = useState(false);
    const [assetData, setAssetData] = useState({});
    const [showHVDetails, setShowHVDetails] = useState(false);
    const [locations, setLocations] = useState([]);
    const [peerAssets, setPeerAssets] = useState([]);
    const [impactFunctionData, setImpactFunctionData] = useState([]);
    const [selectedImpactFunction, setSelectedImpactFunction] = useState({});
    const [selectedHazardMetric, setSelectedHazardMetric] = useState(null);
    const [selectedRiskFactorName, setSelectedRiskFactorName] = useState('');
    const [hazardOnly, setHazardOnly] = useState(false);
    const [hasEmissionData, setHasEmissionData] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const needDisclaimers = ['1242'];
    const { data: appConfigs } = useApiGet('api/appConfig', { keys: 'mapbox_enabled' });

    const mapbox_enabled = appConfigs?.results?.find((i) => i.key === 'mapbox_enabled').value || false;

    const getAggregatedRisksData = async () => {
        const pageData = {
            page: 0,
            rowsPerPage: DEFAULT_PAGE_SIZE,
            searchText: '',
            order: 'desc',
            orderBy: 'relativeValue',
            viewType: 'assets',
        };
        const reqData = { riskDataFilters, viewType: pageData.viewType, asset_id: assetId };
        if (folderId) reqData.folder_id = folderId;
        if (viewId) reqData.view_id = viewId;
        try {
            const response = await riskService.getRealAssetsCachedRisks(customerId, reqData);

            if (response) {
                setChartData(response?.chart_data);
                setGraphData(response?.graph_data);
            }
            setIsMounted(true);
        } catch (error) {
            console.log('Error occured in fetching Asset Risk Data ==>', error.error);
        }
    };

    useEffect(() => {
        let isSubscribed = true;
        let tempRiskDataFilters = { ...riskDataFilters };
        const newRiskDataFilters = [];
        async function fetchData() {
            try {
                if (riskDataFilters.currentCustomerId !== match.params.customerId) {
                    newRiskDataFilters.push({ currentCustomerId: customerId });
                    tempRiskDataFilters = {
                        ...defaultRiskFilters,
                        currentCustomerId: customerId,
                    };
                }

                if (tempRiskDataFilters.riskFactor.length === 0) {
                    tempRiskDataFilters = {
                        ...tempRiskDataFilters,
                        riskFactor: {
                            ...tempRiskDataFilters.riskFactor,
                        },
                    };
                }

                const assetInfo = await getAsset({ assetId, customerId, includeDetails: true });
                assetInfo.customerId = parseInt(customerId, 10);
                if (isSubscribed && assetInfo) {
                    setAssetData(assetInfo);
                    setLocations(
                        assetInfo.locations.map((location) => ({
                            ...location,
                            name: assetInfo.name,
                            asset_id: assetInfo.id,
                        })),
                    );

                    setHasEmissionData(assetInfo.emissions > 0);
                }

                const impactFunctionResponse = await impactFunctionService.getAllByAsset(assetId);

                if (isSubscribed && impactFunctionResponse) {
                    setImpactFunctionData(impactFunctionResponse);
                    setSelectedImpactFunction(
                        impactFunctionResponse.length > 0 ? impactFunctionResponse[0] : { id: 0 },
                    );
                }

                if (isSubscribed) {
                    handleFilterChange(newRiskDataFilters);
                }

                setIsMounted(true);
            } catch (error) {
                console.log('Error occured in fetching Asset Data ==>', error.error);
            }
        }

        fetchData();

        return () => {
            isSubscribed = false;
        };
    }, [assetId, folderId]);

    useEffect(() => {
        getAggregatedRisksData();
    }, [riskDataFilters]);

    useEffect(() => {
        let isSubscribed = true;
        async function fetchData() {
            if (isSubscribed && folderId) {
                const peerAssetResponse = await getAllAssetsForNavigation({ customerId, folderId });
                if (peerAssetResponse) {
                    setPeerAssets(peerAssetResponse.assets);
                }
            }
        }
        if (isMounted) {
            fetchData();
        }
        return () => {
            isSubscribed = false;
        };
    }, [isMounted]);

    const handleShowHVDetails = (event, hazardMetricId, impactFunctionId, riskFactorName) => {
        event.preventDefault();
        event.stopPropagation();

        if (hazardMetricId) {
            setSelectedHazardMetric(hazardMetricId);

            if (HAZARD_ONLY.some((hazard) => hazard.id === hazardMetricId)) {
                setHazardOnly(true);
            } else {
                setHazardOnly(false);
            }
        }

        if (impactFunctionId) {
            setSelectedImpactFunction(
                impactFunctionData.find((impactFunction) => impactFunction.id === impactFunctionId),
            );
        }

        if (riskFactorName) {
            setSelectedRiskFactorName(riskFactorName);
        }

        googleAnalyticsEvent('View Details', 'Hazard/Vulnerability Drawer');
        setShowHVDetails(!showHVDetails);
    };

    const navigateToPeerAsset = (direction) => {
        const currentAssetIndex = peerAssets.findIndex(
            (peerAsset) => peerAsset.id === parseInt(assetId, 10),
        );
        let newAssetIndex = 0;
        if (currentAssetIndex > -1) {
            newAssetIndex = (currentAssetIndex + direction + peerAssets.length) % peerAssets.length;
            const newAssetId = peerAssets[newAssetIndex].id;
            const { widnwoPathname } = window.location;
            const pathsubstring = widnwoPathname.substring(widnwoPathname.indexOf('/assets'));
            const newpath = widnwoPathname.replace(pathsubstring, `/assets/${newAssetId}`);
            history.push({
                pathname: newpath,
            });
        }
    };

    return (
        <>
            {locations?.length > 0 && (
                <HazardVulnerabilityDrawer
                    open={showHVDetails}
                    handleClose={handleShowHVDetails}
                    drawerTitle={
                        hazardOnly
                            ? `${
                                  HAZARD_ONLY.find((hazard) => hazard.id === selectedHazardMetric)
                                      .name
                              } Hazard Details`
                            : `${
                                  selectedImpactFunction && selectedImpactFunction.name
                                      ? selectedImpactFunction.name
                                      : 'Impact Function'
                    } Details`
                    }
                    riskDataFilters={riskDataFilters}
                    vulnerabilityDataToGraph={selectedImpactFunction}
                    assetId={assetId}
                    hazardMetricId={selectedHazardMetric}
                    riskFactorName={selectedRiskFactorName}
                    locations={locations}
                    hazardOnly={hazardOnly}
                />
            )}
            <div>
                <div>
                    <DashboardHeader
                        riskDataFilters={riskDataFilters}
                        handleFilterChange={handleFilterChange}
                        editEntity={true}
                    />
                </div>
                <div className="spg-row">
                    {needDisclaimers.includes(customerId) && <Disclaimer />}
                </div>
                <div className="spg-row spg-ml-0 spg-mr-0 spg-mb-md">
                    <div className="spg-col-4 spg-pl-md">
                        <div>
                            {isMounted ? (
                                <RiskGraph
                                    riskGraphData={
                                        isMounted && graphData?.length === 0
                                            ? { absoluteValues: [] }
                                            : graphData?.dataToGraphResults
                                    }
                                    riskDataFilters={riskDataFilters}
                                    handleFilterChange={handleFilterChange}
                                    entityId={assetId}
                                    entityType={entityType}
                                    investmentFolderId={folderId}
                                />
                            ) : (
                                <H5>Gathering risk details ...</H5>
                            )}
                        </div>
                        <div>
                            {isMounted ? (
                                <HazardImpactViewer
                                    hazardImpactChartData={chartData?.dataToChartResults}
                                    riskDataFilters={riskDataFilters}
                                    handleFilterChange={handleFilterChange}
                                    handleDetailButtonClicked={handleShowHVDetails}
                                    navigateToPeerEntity={navigateToPeerAsset}
                                    peerEntities={peerAssets}
                                    hasEmissionData={hasEmissionData}
                                    viewType="asset"
                                    entityType={entityType}
                                    entityId={assetId}
                                    locations={locations}
                                    investmentFolderId={folderId}
                                />
                            ) : (
                                <H5>Gathering risk details...</H5>
                            )}
                        </div>
                    </div>
                    <div className="spg-col-8">
                        <Card hasBorder hasRoundedCorner>
                            <div>
                                {mapbox_enabled ? (
                                    isMounted ? (
                                        <MapBoxMap assetData={[assetData]} isPortfolioView={false} />
                                    ) : (
                                        <H5>Gathering asset details ...</H5>
                                    )
                                ) : (
                                    isMounted ? (
                                        <MapWrapper locations={locations} displayLegend />
                                    ) : (
                                        <H5>Gathering asset details ...</H5>
                                    )
                                )}
                                { isMounted ? (
                                    <LocationInfoTable asset={assetData} />
                                ) : (
                                    <H5>Gathering asset details ...</H5>
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

AssetDetails.propTypes = {
    entityType: PropTypes.number,
    match: PropTypes.object,
};
