import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApiGet } from 'hooks/useApiGet';
import MapBoxMap from 'components/MapBoxMap';
import MapWrapper from 'components/LocationMap';
import { getAllAssetsForFolder, getCustomerAssets } from '../../services/assetService';
import riskColor from '../../utils/riskColor';
import { useHierarchyContext } from '../../context/HierarchyContext';

export default function InvestmentAssetsLocationMap({
    entityType,
    entityId,
    riskDataFilters,
    investmentId,
    folderId,
    viewId,
    customerId,
    tableData,
}) {
    const { currentPath } = useHierarchyContext();
    const customerIdFromPath = currentPath.substring(1).split('/')?.[1];

    // State variables for configurations
    const [locationMapThreshold, setLocationMapThreshold] = useState(null);
    const [mapboxEnabled, setMapboxEnabled] = useState(null); // Initialize as null

    // Fetch configuration data
    const { data: appConfigs, isLoading: configLoading } = useApiGet('api/appConfig', {
        keys: ['location_map_threshold', 'mapbox_enabled'],
    });

    // Fetch and set configuration values
    useEffect(() => {
        if (!configLoading && appConfigs) {
            const locationThresholdConfig = appConfigs.results.find(
                (i) => i.key === 'location_map_threshold'
            );
            const mapboxEnabledConfig = appConfigs.results.find(
                (i) => i.key === 'mapbox_enabled'
            );

            setLocationMapThreshold(
                locationThresholdConfig ? parseInt(locationThresholdConfig.value, 10) : null
            );
            setMapboxEnabled(
                mapboxEnabledConfig
                    ? mapboxEnabledConfig.value === 'true' || mapboxEnabledConfig.value === true
                    : false
            );
        }
    }, [configLoading, appConfigs]);

    const mapProhibited =
        customerIdFromPath === '2109' ||
        customerIdFromPath === '4' ||
        customerIdFromPath === '1122';

    if (mapProhibited) {
        return null;
    }

    const [locations, setLocations] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [transformedLocations, setTransformedLocations] = useState([]);

    const addRiskInfoToInvestmentLocations = (locationsArray, assetTableData) =>
        locationsArray.map((location) => {
            const findTableData = assetTableData.find(
                (asset) => asset.entityId === location.asset_id
            );
            const assetObject = findTableData || {
                absoluteValue: '$0.00',
                relativeValue: '0.0 %',
            };
            return {
                ...location,
                ...assetObject,
                color: riskColor.determineColor(assetObject.relative_value, 'relative'),
            };
        });

    const updateLocationEntityNames = (locs, assets) => {
        locs.forEach((loc) => {
            const matchingAsset = assets.find((asset) => asset.asset_id === loc.asset_id);
            if (matchingAsset) loc.entity_name = matchingAsset.asset_name;
        });
        return locs;
    };

    const transformData = (assetLocations) =>
        assetLocations.map((location) => ({
            assetTypeId: location.asset_folder_id || null,
            customerId: customerId || null,
            assetValue: location.total_value || null,
            assetsTags: [],
            assetsTorefId: { refName: location.entity_name || '' },
            emissions: location.relative_value || 0,
            id: location.entityId || location.asset_id,
            locations: [
                {
                    lat: location.lat,
                    long: location.long,
                    absolute_value: location.absolute_value,
                    country: location.country || '',
                    scoringStatus: location.scoringStatus || '',
                    status: location.status || null,
                    street_address: location.street_address || '',
                    color: location.color || '',
                },
            ],
            name: location.entity_name || '',
            refId: location.entityId ? String(location.entityId) : '',
        }));

        const fetchAllAssetsForFolder = async () => {
            try {
                const pageSize = 40000;

                console.log('Fetching first page:', { customerId, folderId, viewId, pageSize });

                // Fetch the first page
                const firstPageResponse = await getAllAssetsForFolder({
                    customerId,
                    folderId,
                    viewId,
                    page: 1,
                    pageSize,
                });

                const { totalPages } = firstPageResponse;
                console.log(`Total pages: ${totalPages}`);

                const allResults = firstPageResponse.results;

                if (!totalPages || totalPages <= 1) {
                    console.log('Returning results for a single page.');
                    return allResults;
                }

                // Prepare promises for additional pages
                const promises = [];
                for (let currentPage = 2; currentPage <= totalPages; currentPage += 1) {
                    console.log(`Preparing request for page ${currentPage}`);
                    promises.push(
                        getAllAssetsForFolder({
                            customerId,
                            folderId,
                            viewId,
                            page: currentPage,
                            pageSize,
                        })
                    );
                }

                // Resolve all promises concurrently
                console.log('Fetching additional pages...');
                const responses = await Promise.all(promises);

                // Combine all results
                responses.forEach((response) => {
                    allResults.push(...response.results);
                });

                console.log('All pages fetched successfully.');
                return allResults;
            } catch (error) {
                console.error('Error fetching assets:', error);
                throw error;
            }
        };

    // Fetch asset data when configurations are ready
    useEffect(() => {
        async function fetchData() {
            if (entityId && locationMapThreshold !== null) {
                const assetCountData = await getCustomerAssets(customerId, {
                    assetcount: true,
                });
                if (
                    assetCountData.active_asset_count < locationMapThreshold ||
                    folderId ||
                    viewId
                ) {
                    console.log('Fetching all assets for folder...', 'folderId:', folderId, 'viewId:', viewId, 'customerId:', customerId);
                    const investmentAssetLocationsResponse = await fetchAllAssetsForFolder();

                    if (
                        investmentAssetLocationsResponse.length < locationMapThreshold
                    ) {
                        const investmentAssetLocations =
                            investmentAssetLocationsResponse.reduce(
                                (accumulator, currentAsset) =>
                                    accumulator.concat(currentAsset.location_list),
                                []
                            );

                        const investmentAssetLocationsWithRisks =
                            addRiskInfoToInvestmentLocations(
                                investmentAssetLocations,
                                tableData,
                                riskDataFilters
                            );

                        const investmentAssetLocationsWithRisksAndNames =
                            updateLocationEntityNames(
                                investmentAssetLocationsWithRisks,
                                investmentAssetLocationsResponse
                            );

                        setShowMap(true);
                        setLocations(investmentAssetLocationsWithRisksAndNames);

                        // Transform the data structure for the map component
                        const transformedData = transformData(
                            investmentAssetLocationsWithRisksAndNames
                        );
                        setTransformedLocations(transformedData);
                    }
                }
            }
        }
        if (mapboxEnabled !== null && locationMapThreshold !== null) {
            fetchData();
        }
    }, [
        riskDataFilters,
        entityId,
        entityType,
        folderId,
        investmentId,
        viewId,
        locationMapThreshold,
        mapboxEnabled, // Include mapboxEnabled in dependencies
    ]);

    // Show loading indicator until configurations are ready
    if (configLoading || mapboxEnabled === null) {
        return <p>Loading map configuration...</p>;
    }

    if (!showMap) {
        return null;
    }

    console.log('Transformed locations:', transformedLocations);

    // Render the appropriate map based on configurations
    return mapboxEnabled ? (
        <MapBoxMap assetData={transformedLocations} isPortfolioView={true} />
    ) : (
        <MapWrapper locations={locations} displayLegend />
    );
}

InvestmentAssetsLocationMap.propTypes = {
    customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    entityType: PropTypes.number,
    folderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleClick: PropTypes.func,
    investmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    riskDataFilters: PropTypes.object,
    tableData: PropTypes.array,
};
