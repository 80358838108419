import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapBoxMap.scss';
import {
    MAPBOX_ATLAS_URL,
    MAPBOX_ATLAS_ACCESS_TOKEN,
    HEAT_MAP_DATA,
    DECADE_OPTIONS,
    SCENARIO_MAPPING,
} from 'utils/constants';

mapboxgl.accessToken = MAPBOX_ATLAS_ACCESS_TOKEN;
mapboxgl.config.API_URL = MAPBOX_ATLAS_URL;

const defaultZoom = 8;
const defaultCenter = [-78.8986, 35.994]; // Fallback center if no valid location is found

const MapBoxMap = ({ assetData = [], isPortfolioView }) => {
    const mapContainerRef = useRef(null);
    const map = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const baseTileset = 'mapbox://styles/mapbox/streets-v11';

    // Local state for map controls and selections
    const [isHeatmapVisible, setIsHeatmapVisible] = useState(false);
    const [mapStyle] = useState(baseTileset);
    const [selectedHeatMap, setSelectedHeatMap] = useState(HEAT_MAP_DATA[0]?.value);
    const [selectedScenario, setSelectedScenario] = useState(SCENARIO_MAPPING[0]?.value);
    const [selectedDecade, setSelectedDecade] = useState(DECADE_OPTIONS[0]?.value);
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    // Reference to hold the popup instance
    const popupRef = useRef(null);

    // Ref to track if event listeners have been added
    const eventListenersAddedRef = useRef(false);

    // Toggles and handlers for UI elements
    const toggleMenuVisibility = () => setIsMenuVisible((prev) => !prev);
    const handleHeatmapToggle = () => setIsHeatmapVisible(!isHeatmapVisible);
    const handleHeatMapChange = (e) => setSelectedHeatMap(e.target.value);
    const handleScenarioChange = (e) => setSelectedScenario(e.target.value);
    const handleDecadeChange = (e) => setSelectedDecade(e.target.value);

    // Generates the heatmap URL based on selected parameters
    const getHeatMapUrl = () =>
        `${MAPBOX_ATLAS_URL}/v4/atlas-user.${selectedHeatMap}_${selectedScenario}_${selectedDecade}/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_ATLAS_ACCESS_TOKEN}`;

    // Adds or updates the heatmap layer on the map
    const updateRasterLayer = () => {
        const heatMapUrl = getHeatMapUrl();
        if (map.current.getSource('dynamic_heatmap')) {
            map.current.getSource('dynamic_heatmap').setTiles([heatMapUrl]);
        } else {
            map.current.addSource('dynamic_heatmap', {
                type: 'raster',
                tiles: [heatMapUrl],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 8,
            });
            map.current.addLayer({
                id: 'heatmap_layer',
                type: 'raster',
                source: 'dynamic_heatmap',
                paint: { 'raster-opacity': 0.7 },
            });
        }
    };

    // Extract the first valid location from assetData
    const getFirstValidLocation = () => {
        for (const asset of assetData) {
            if (asset.locations && asset.locations.length > 0) {
                for (const location of asset.locations) {
                    const latitude = location.lat || location.latitude;
                    const longitude = location.long || location.longitude || location.lng;

                    if (latitude !== undefined && longitude !== undefined) {
                        return [longitude, latitude];
                    }
                }
            }
        }
        return null;
    };

    // Initialize the map on first render
    useEffect(() => {
        if (!mapContainerRef.current) return;

        // Get the first valid location or fallback to default center
        const initialCenter = getFirstValidLocation() || defaultCenter;

        // Initialize Mapbox map
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle,
            center: initialCenter,
            zoom: defaultZoom,
        });

        // Navigation and fullscreen controls
        map.current.addControl(
            new mapboxgl.NavigationControl({ showCompass: true }),
            'bottom-right'
        );
        map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');

        // When the map's style has loaded
        map.current.on('style.load', () => {
            setMapLoaded(true);

            // Update map data now that the style is ready
            updateMapData();
        });

        // Cleanup function
        return () => {
            if (map.current) {
                // Remove event listeners
                map.current.off('click', 'clusters');
                map.current.off('mouseenter', 'clusters');
                map.current.off('mouseleave', 'clusters');

                map.current.off('click', 'unclustered-point');
                map.current.off('mouseenter', 'unclustered-point');
                map.current.off('mouseleave', 'unclustered-point');

                map.current.off('click'); // Remove the general map click listener

                // Remove popup if exists
                if (popupRef.current) {
                    popupRef.current.remove();
                    popupRef.current = null;
                }

                map.current.remove();
            }
        };
    }, []); // Empty dependency array ensures this runs only once

    // Update map data when assetData changes
    useEffect(() => {
        if (!mapLoaded || !map.current) return;

        updateMapData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetData]);

    const updateMapData = () => {
        if (!map.current || !map.current.getStyle()) return;

        // Ensure assetData is an array of assets
        const assetArray = Array.isArray(assetData) ? assetData : [assetData];

        const features = assetArray
        .flatMap((asset) =>
            asset.locations
                ?.map((location, index) => {
                    const latitude = location.lat || location.latitude;
                    const longitude = location.long || location.longitude || location.lng;

                    // Only include valid coordinates
                    if (latitude !== undefined && longitude !== undefined) {
                        // Determine the name property based on isPortfolioView
                        const name = isPortfolioView
                            ? `<a href="/real-assets/${asset.customerId}/assets/${asset.id}" target="_blank" rel="noopener noreferrer">${asset.name}</a>`
                            : asset.name;

                        return {
                            type: 'Feature',
                            properties: {
                                index: asset.locations.length > 1 ? index + 1 : '',
                                name,
                                id: asset.id,
                                color: location.color || asset.color || '#11b4da',
                            },
                            geometry: { type: 'Point', coordinates: [longitude, latitude] },
                        };
                    }
                    return null; // Exclude invalid points
                })
                .filter(Boolean) // Remove null entries
        )
        .filter(Boolean);

        // Define GeoJSON source for assets
        const geojson = { type: 'FeatureCollection', features: features || [] };

        if (map.current.getSource('assets')) {
            // Update existing source data
            map.current.getSource('assets').setData(geojson);
        } else if (features.length > 0) {
            // Add the source and layers
            map.current.addSource('assets', {
                type: 'geojson',
                data: geojson,
                cluster: true,
                clusterMaxZoom: 12,
                clusterRadius: 60,
            });

            // Add cluster layer
            if (!map.current.getLayer('clusters')) {
                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': '#00ACC1',
                        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                        'circle-opacity': 0.6,
                    },
                });
            }

            // Add cluster count layer
            if (!map.current.getLayer('cluster-count')) {
                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'assets',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12,
                    },
                });
            }

            // Add unclustered point layer
            if (!map.current.getLayer('unclustered-point')) {
                map.current.addLayer({
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'assets',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': ['get', 'color'],
                        'circle-radius': 7,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#fff',
                    },
                });
            }

            // Add unclustered point index layer
            if (!map.current.getLayer('unclustered-point-index')) {
                map.current.addLayer({
                    id: 'unclustered-point-index',
                    type: 'symbol',
                    source: 'assets',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'text-field': ['get', 'index'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 10,
                        'text-offset': [-0.1, 0.1],
                    },
                    paint: { 'text-color': '#000' },
                });
            }

            // Add event listeners if not already added
            if (!eventListenersAddedRef.current) {
                // Add event listeners for clusters
                map.current.on('click', 'clusters', (e) => {
                    const clusters = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters'],
                    });
                    if (!clusters.length) return;

                    const clusterId = clusters[0].properties.cluster_id;

                    map.current
                        .getSource('assets')
                        .getClusterExpansionZoom(clusterId, (err, zoom) => {
                            if (err) return;

                            map.current.easeTo({
                                center: clusters[0].geometry.coordinates,
                                zoom,
                            });
                        });
                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });

                // Add event listener for unclustered points
                map.current.on('click', 'unclustered-point', (e) => {
                    if (e.features.length > 0) {
                        const feature = e.features[0];
                        const coordinates = feature.geometry.coordinates.slice();
                        const { name, id } = feature.properties;

                        // Close any existing popup
                        if (popupRef.current) {
                            popupRef.current.remove();
                            popupRef.current = null;
                        }

                        // Create a new popup
                        popupRef.current = new mapboxgl.Popup({
                            closeButton: false,
                            closeOnClick: false,
                        })
                            .setLngLat(coordinates)
                            .setHTML(`<strong>${name}</strong><br>ID: ${id}`)
                            .addTo(map.current);
                    }
                });

                map.current.on('mouseenter', 'unclustered-point', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'unclustered-point', () => {
                    map.current.getCanvas().style.cursor = '';
                });

                // Adjusted map click listener to prevent interference
                map.current.on('click', (e) => {
                    // Check if the click was on an interactive layer
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['unclustered-point', 'clusters'],
                    });

                    if (features.length === 0) {
                        // If there is a popup open, close it
                        if (popupRef.current) {
                            popupRef.current.remove();
                            popupRef.current = null;
                        }
                    }
                });

                eventListenersAddedRef.current = true;
            }
        }
    };

    // Toggle heatmap layer visibility
    useEffect(() => {
        if (!mapLoaded || !map.current) return;

        if (isHeatmapVisible) {
            updateRasterLayer();
        } else if (map.current.getLayer('heatmap_layer')) {
            map.current.removeLayer('heatmap_layer');
            map.current.removeSource('dynamic_heatmap');
        }
    }, [mapLoaded, selectedHeatMap, selectedScenario, selectedDecade, isHeatmapVisible]);

    return (
        <div
            ref={mapContainerRef}
            style={{ position: 'relative', width: '100%', height: '600px' }}
        >
            {/* Heatmap toggle button */}
            <button
                type="button"
                onClick={toggleMenuVisibility}
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 2,
                    background: 'white',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                {isMenuVisible ? '-' : '+'}
            </button>
            {isMenuVisible && (
                <div
                    className="map-menu"
                    style={{
                        position: 'absolute',
                        top: 50,
                        right: 10,
                        zIndex: 3,
                        backgroundColor: 'white',
                        padding: '12px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        width: '220px',
                        fontFamily: 'Arial, sans-serif',
                    }}
                >
                    <label
                        style={{
                            display: 'block',
                            marginBottom: '8px',
                            color: '#333',
                            fontWeight: '500',
                        }}
                    >
                        Heatmap:
                        <button
                            type="button"
                            onClick={handleHeatmapToggle}
                            style={{
                                marginLeft: '10px',
                                padding: '4px 8px',
                                backgroundColor: isHeatmapVisible ? '#3b9ddd' : '#ccc',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {isHeatmapVisible ? 'On' : 'Off'}
                        </button>
                    </label>
                    <label
                        style={{
                            marginBottom: '8px',
                            color: '#333',
                            fontWeight: '500',
                        }}
                    >
                        Hazard
                        <select
                            value={selectedHeatMap}
                            onChange={handleHeatMapChange}
                            style={{ width: '100%', padding: '6px', marginTop: '4px' }}
                        >
                            {HEAT_MAP_DATA.map((heatMap) => (
                                <option key={heatMap.value} value={heatMap.value}>
                                    {heatMap.label}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label
                        style={{
                            marginBottom: '8px',
                            color: '#333',
                            fontWeight: '500',
                        }}
                    >
                        Scenario
                        <select
                            value={selectedScenario}
                            onChange={handleScenarioChange}
                            style={{ width: '100%', padding: '6px', marginTop: '4px' }}
                        >
                            {SCENARIO_MAPPING.map((scenario) => (
                                <option key={scenario.value} value={scenario.value}>
                                    {scenario.label}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label
                        style={{
                            color: '#333',
                            fontWeight: '500',
                        }}
                    >
                        Decade
                        <select
                            value={selectedDecade}
                            onChange={handleDecadeChange}
                            style={{ width: '100%', padding: '6px', marginTop: '4px' }}
                        >
                            {DECADE_OPTIONS.map((decade) => (
                                <option key={decade.value} value={decade.value}>
                                    {decade.label}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
            )}
            {isPortfolioView && (
                <div
                    className="legend"
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        zIndex: 3,
                        backgroundColor: 'white',
                        padding: '8px',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: 'green',
                                marginRight: '6px',
                            }}
                        ></div>
                        <span>0-10% of Asset Value</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: 'yellow',
                                marginRight: '6px',
                            }}
                        ></div>
                        <span>11-15% of Asset Value</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: 'red',
                                marginRight: '6px',
                            }}
                        ></div>
                        <span>16-100% of Asset Value</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                backgroundColor: '#00ACC1',
                                marginRight: '6px',
                            }}
                        ></div>
                        <span>Asset Cluster</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(MapBoxMap);
